.comparison_chart_embla {
  --slide-height: 19rem;
  --slide-spacing: 0rem;
}

.comparison_chart_embla__viewport {
  overflow: hidden;
  width: 100%;
}

.comparison_chart_embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.comparison_chart_embla__slide {
  flex: 0 0 var(--comparison-chart-slide-size);
}

.comparison_chart_embla__button {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
}

.comparison_chart_embla__button:focus {
  outline: 2px solid #1f297d;
}

.comparison_chart_embla__button:disabled .comparison_chart_embla__button__svg {
  color: #f2f2f2 !important;
}

.comparison_chart_embla__button:disabled:hover {
  background-color: unset;
}

.comparison_chart_embla__dot__active {
  background-color: #0070f3;
}

.comparison_chart_embla__dot {
  -webkit-appearance: none;
  touch-action: manipulation;
}

.comparison_chart_embla__dot__active:after {
  background-color: #352b2b;
}

.slide_com {
  position: relative;
}

.header_title .slide_com {
  padding: 32px;
  font-size: 18px;
  font-weight: 500;
  background-color: #ffffff;
}

.spacing_title .slide_com::before {
  content: '';
  position: absolute;
  height: 25px;
  width: calc(100% + 1px);
  border: 1px solid #E4E4E4;
  border-left-width: 0.5px;
  border-right-width: 0.5px;
  top: -58px;
  left: -0.5px;
}

.firstBlock {
  padding-top: 23px;
  overflow: hidden;
}

.firstBlock .comparison_chart_embla__viewport {
  overflow: unset !important;
}

.empthy_block .slide_com {
  min-height: 400px;
}