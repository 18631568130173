/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.text-nowrap {
  text-wrap: nowrap;
}

.dark-mode h3 {
  color: var(--white);
}
.dark-mode a {
  color: var(--white) !important;
}
.dark-mode a :hover {
  text-decoration: underline;
}
.dark-mode a :focus {
  outline: 2px solid var(--white);
}

.dark-mode a::after {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23ffffff;%7D .st1%7Bfill:none;%7D %3C/style%3E %3Cg%3E %3Cpath id='Path' class='st0' d='M5.3,1.8c-0.4-0.4-0.5-1-0.1-1.4s1-0.5,1.4-0.1l12.5,11c0.5,0.4,0.5,1.1,0,1.5l-12.5,11 c-0.4,0.4-1,0.3-1.4-0.1s-0.3-1,0.1-1.4L17,12L5.3,1.8z'/%3E %3Crect id='Rectangle' y='0' class='st1' width='24' height='24'/%3E %3C/g%3E %3C/svg%3E ");
}

.link-wrapper::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 24 24'%3E%3Cpath d='M5.3 1.8c-.4-.4-.5-1-.1-1.4s1-.5 1.4-.1l12.5 11c.5.4.5 1.1 0 1.5l-12.5 11c-.4.4-1 .3-1.4-.1s-.3-1 .1-1.4L17 12z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%233964bf'/%3E%3Cpath d='M0 0h24v24H0z' style='fill:none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.125rem;
  content: '';
  display: inline-block;
  height: 1.125rem;
  left: 0.5rem;
  position: relative;
  top: 0.1875rem;
  width: 1.125rem;
}

.single-line-promo--burgundy {
  background-color: var(--burgendy);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--burgundy-gradient-red-line {
  background-image: var(--burgendy-gradient-red-line);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--medium-red {
  background-color: var(--medium-red);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--medium-red-gradient {
  background-image: var(--medium-red-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--vibrant-red {
  background-color: var(--vibrant-red);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--vibrant-red-gradient {
  background-image: var(--vibrant-red-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--orange-gradient {
  background-image: var(--orange-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--warm-orange {
  background-color: var(--warm-orange);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--warm-orange-gradient {
  background-image: var(--warm-orange-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--plum {
  background-color: var(--plum);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--plum-gradient {
  background-image: var(--plum-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--slate-blue {
  background-color: var(--slate-blue);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--slate-blue-gradient {
  background-image: var(--slate-blue-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--teal {
  background-color: var(--teal);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--teal-gradient {
  background-image: var(--teal-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--mint {
  background-color: var(--mint);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--mint-gradient {
  background-image: var(--mint-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--green {
  background-color: var(--green);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--green-gradient {
  background-image: var(--green-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--oatmeal {
  background-color: var(--oatmeal);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--oatmeal-gradient {
  background-image: var(--oatmeal-gradient);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--white {
  background-color: var(--white);
  background-size: 30% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--double {
  background-size: contain, auto !important;
  background-repeat: no-repeat, repeat-x;
}

.single-line-promo--lined-red.single-line-promo--burgundy {
  background-image: var(--single-line-promo--burgundy);
}

.single-line-promo--burgundy-gradient-white-line {
  background-image: var(--burgundy-gradient-white-line);
  background-size: 30% 100%, 100% 100%;
  background-repeat: no-repeat;
}

.single-line-promo--lined.single-line-promo--burgundy,
.single-line-promo--lined.single-line-promo--medium-red,
.single-line-promo--lined.single-line-promo--vibrant-red,
.single-line-promo--lined.single-line-promo--warm-orange,
.single-line-promo--lined.single-line-promo--plum,
.single-line-promo--lined.single-line-promo--slate-blue,
.single-line-promo--lined.single-line-promo--teal,
.single-line-promo--lined.single-line-promo--green {
  background-image: var(--line-promo-multiple);
}

.single-line-promo--lined.single-line-promo--white {
  background-image: var(--single-line-promo--white);
}
.single-line-promo--double {
  background-image: var(--single-line-promo--double);
}

.single-line-promo--lined.single-line-promo--mint,
.single-line-promo--lined.single-line-promo--oatmeal {
  background-image: var(--single-line-promo--mint-oatmeal);
}

@media (min-width: 0px) and (max-width: 767px) {
  .single-line-promo--oatmeal-gradient {
    background-image: var(--oatmeal-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }
  .single-line-promo--green-gradient {
    background-image: var(--green-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }
  .single-line-promo--mint-gradient {
    background-image: var(--mint-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--teal-gradient {
    background-image: var(--teal-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--slate-blue-gradient {
    background-image: var(--slate-blue-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--plum-gradient {
    background-image: var(--plum-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--warm-orange-gradient {
    background-image: var(--warm-orange-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--vibrant-red-gradient {
    background-image: var(--vibrant-red-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--orange-gradient {
    background-image: var(--orange-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--medium-red-gradient {
    background-image: var(--medium-red-gradient-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--burgundy-gradient-red-line {
    background-image: var(--burgendy-gradient-red-line-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--burgundy-gradient-white-line {
    background-image: var(--burgundy-gradient-white-line-m);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--lined-red.single-line-promo--burgundy {
    background-image: var(--single-line-promo--burgundy-m);
  }

  .single-line-promo--lined.single-line-promo--burgundy,
  .single-line-promo--lined.single-line-promo--medium-red,
  .single-line-promo--lined.single-line-promo--vibrant-red,
  .single-line-promo--lined.single-line-promo--warm-orange,
  .single-line-promo--lined.single-line-promo--plum,
  .single-line-promo--lined.single-line-promo--slate-blue,
  .single-line-promo--lined.single-line-promo--teal,
  .single-line-promo--lined.single-line-promo--green {
    background-image: var(--line-promo-multiple-m);
  }

  .single-line-promo--lined.single-line-promo--white {
    background-image: var(--single-line-promo--white-m);
  }

  .single-line-promo--double {
    background-image: var(--single-line-promo--double-m);
    background-position: right 100%, bottom !important;
  }

  .single-line-promo--lined.single-line-promo--mint,
  .single-line-promo--lined.single-line-promo--oatmeal {
    background-image: var(--single-line-promo--mint-oatmeal-m);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .single-line-promo--oatmeal-gradient {
    background-image: var(--oatmeal-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }
  .single-line-promo--green-gradient {
    background-image: var(--green-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }
  .single-line-promo--mint-gradient {
    background-image: var(--mint-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--teal-gradient {
    background-image: var(--teal-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--slate-blue-gradient {
    background-image: var(--slate-blue-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--plum-gradient {
    background-image: var(--plum-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--warm-orange-gradient {
    background-image: var(--warm-orange-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--vibrant-red-gradient {
    background-image: var(--vibrant-red-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--orange-gradient {
    background-image: var(--orange-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--medium-red-gradient {
    background-image: var(--medium-red-gradient-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--burgundy-gradient-red-line {
    background-image: var(--burgendy-gradient-red-line-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--burgundy-gradient-white-line {
    background-image: var(--burgundy-gradient-white-line-t);
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat;
  }

  .single-line-promo--lined-red.single-line-promo--burgundy {
    background-image: var(--single-line-promo--burgundy-t);
  }

  .single-line-promo--lined.single-line-promo--burgundy,
  .single-line-promo--lined.single-line-promo--medium-red,
  .single-line-promo--lined.single-line-promo--vibrant-red,
  .single-line-promo--lined.single-line-promo--warm-orange,
  .single-line-promo--lined.single-line-promo--plum,
  .single-line-promo--lined.single-line-promo--slate-blue,
  .single-line-promo--lined.single-line-promo--teal,
  .single-line-promo--lined.single-line-promo--green {
    background-image: var(--line-promo-multiple-t);
  }

  .single-line-promo--lined.single-line-promo--white {
    background-image: var(--single-line-promo--white-t);
  }

  .single-line-promo--double {
    background-image: var(--single-line-promo--double-t);
    background-position: right 100%, bottom !important;
  }

  .single-line-promo--lined.single-line-promo--mint,
  .single-line-promo--lined.single-line-promo--oatmeal {
    background-image: var(--single-line-promo--mint-oatmeal-t);
  }
}
