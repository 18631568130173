.slideshow {
  --slide-height: 19rem;
  --slide-spacing: 24px;
  --slide-size: 80%;
}

.slideshow__container {
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.slideshow__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.slideshow__button,
.slideshow__dot {
  touch-action: manipulation;
}

.slideshow__button__svg > svg {
  width: 24px;
  height: 24px;
}

.slideshow__button:focus .slideshow__button__svg {
  color: #1f297d;
}

.slideshow__button:disabled .slideshow__button__svg {
  opacity: 0.1;
}
.slideshow__button:disabled:hover {
  background-color: unset;
}

.slideshow__dot__selected:after {
  background-color: #352b2b;
}

@media screen and (max-width: 1280px) {
  .slideshow {
    --slide-size: 90%;
  }
}

@media screen and (max-width: 768px) {
  .slideshow {
    --slide-size: 90%;
  }

  .slideshow__dot {
    width: 26px;
  }
}
